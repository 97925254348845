.rc-tree-treenode {
  cursor: pointer;
  border: 1px solid transparent;
  padding-bottom: 2px;
  line-height: 1.2;
  display: flex;
}

.rc-tree-treenode:hover {
  border: 1px solid rgb(219, 219, 219);
}

.rc-tree-node-content-wrapper-normal {
  margin-left: 8px;
}

.rc-tree-treenode-selected {
  background-color: #ffeaec;
}

.rc-tree-title {
  font-size: 14px;
  margin-left: 10px;
}

.rc-tree-indent {
  display: flex;
  height: 0;
  vertical-align: bottom;
}

.rc-tree-indent-unit {
  display: inline-block;
  width: 16px;
}

.rc-tree-switcher {
  width: 10px;
  margin-right: 8px;
  transition: all 0.3s ease;
  z-index: 10;
  display: flex;
  align-items: center;
}

.rc-tree-switcher_close > svg {
  transform: rotate(-90deg);
}

.rc-tree .rc-tree-treenode.drop-container > .draggable::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  box-shadow: inset 0 0 0 2px red;
  content: "";
}

.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
  position: relative;
  display: flex;
  height: 24px;
  align-items: center;
  margin: 0px;
  padding: 0;
  margin-left: -50px;
  padding-left: 50px;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  width: 200%;
}

.rc-tree-iconEle {
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-tree-switcher-noop {
  margin-left: 6px;
}

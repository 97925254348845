.audienceCard {
  border: 1px solid #f2f2f6;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  min-height: 71px;
  position: relative;
}

.withPointer {
  cursor: pointer;
}

.checkIcon {
  position: absolute;
  left: 3px;
  top: 3px;
}

.selectedAudienceCard {
  border: 1px solid #bebed6;
  background-color: #f9f9ff;
}

.audienceNameAndAmount {
  display: flex;
  flex-direction: column;
}

.audienceCardTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
}

.audienceCardCost {
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
}

.audienceCardCostAndMore {
  display: flex;
  align-items: center;
  gap: 10px;
}

.moreButton {
  border: 1px solid #e2e2fc;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.quoteTableSection {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  gap: 0;
  font-size: 16px;
}

.quoteTableHeader {
  display: contents;
  font-weight: 600;
  font-size: 16px;
}

.quoteTableHeader > div {
  padding: 4px 0;
  border-bottom: 1px solid #e2e2fc;
}

.quoteTableHeader > div:nth-child(2),
.quoteTableHeader > div:last-child {
  text-align: center;
}

.quoteTableLine {
  display: contents;
}

.quoteTableLine > div {
  padding: 4px 0;
  border-bottom: 1px solid #e2e2fc;
}

.quoteTableLine > div:nth-child(2),
.quoteTableLine > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.quoteTableSubtotalLine {
  display: contents;
}

.quoteTableSubtotalLine > div:nth-child(2) {
  padding: 8px 28px;
  text-align: start;
  border-bottom: 1px solid #e2e2fc;
}

.quoteTableSubtotalLine > div:last-child {
  padding: 8px 0;
  text-align: center;
  border-bottom: 1px solid #e2e2fc;
}

.quoteTableTotalLine {
  display: contents;
  font-weight: 600;
}

.quoteTableTotalLine > div:nth-child(2) {
  padding: 8px 0px 8px 28px;
  text-align: start;
  background: #f9f9ff;
}

.quoteTableTotalLine > div:last-child {
  padding: 8px 0px;
  background: #f9f9ff;
  text-align: center;
}

.quoteSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid #e2e2fc;
}

.quoteSectionTitle {
  font-size: 18px;
  font-weight: 800;
}

.quoteCouponCodeLink {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-decoration-line: underline;
  color: #4f5b94;
  text-align: right;
  padding: 8px 0px;
}

.quoteCouponCodeContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0px;
}

.quoteCouponCodeCloseButton {
  cursor: pointer;
}

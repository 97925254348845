.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  min-width: 869px;
  height: 100vh;
  gap: 10px;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.closeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.closeButton {
  cursor: pointer;
  padding: 0;
}

.titleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0%;
}

.reviewDesignAdvise {
  width: 100%;
  padding: 13px 18px;
  display: flex;
  flex-direction: column;
  background-color: #e2e2fc;
}

.reviewDesignAdviseTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.reviewDesignAdviseTitle > svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.reviewDesignAdviseContent {
  font-size: 12px;
}

.designErrorContainer {
  width: 100%;
  padding: 13px 18px;
  display: flex;
  flex-direction: column;
  background-color: #f5e2e2;
}

.designErrorTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.designErrorTitle > svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.designErrorContent {
  font-size: 12px;
}

.footer {
  height: 81px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.fileName {
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0px;
}

.buttonsContainer {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.uploadButton svg {
  margin-right: 5px;
}

.circularProgress {
  position: absolute;
  right: 10px;
}

@media (max-width: 869px) {
  .modalContent {
    min-width: 100%;
    width: 100vw;
  }
}

.modalBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  width: 555px;
  border-radius: 8px;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.closeButton {
  cursor: pointer;
  padding: 0;
  color: #292d32;
}

.modalTitleContainer {
  align-self: flex-start;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.modalTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0px;
  vertical-align: middle;
}

.modalFooter {
  gap: 10px;
  display: flex;
  width: 100%;
}

.button {
  width: 50%;
}

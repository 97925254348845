.infoCardContainer {
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(39, 55, 84, 0.08);
}

.infoCardHeaderContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
}

.seeMoreToggle {
  display: flex;
  padding: 0px 8px;
  gap: 8px;
  align-items: center;
}

.seeMoreToggle:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.toggleIcon {
  color: #2e3860;
}

.toggleIconOpen {
  transform: rotate(180deg);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkIconInfoCard {
  width: 18px;
  height: 18px;
}

.infoCardContainer form,
.infoCardContainer > div:not(.infoCardHeaderContainer) {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, margin 0.5s ease-in-out;
  opacity: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100vh;
}

.infoCardContainer.closed form,
.infoCardContainer.closed > div:not(.infoCardHeaderContainer) {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, margin 0.5s ease-in-out;
  max-height: 0;
  opacity: 0;
  margin: 0;
}

.infoCardAlertInvalidDatesContainer {
  padding: 0 32px;
}

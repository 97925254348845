.sectionTitle {
  font-size: 18px;
  font-weight: 800;
}

.detailsRow {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 8px;
  padding-top: 8px;
}

.detailsLabel {
  font-size: 16px;
  line-height: 24px;
  color: #0c172c;
}

.detailsValue {
  font-size: 16px;
  line-height: 24px;
  color: #0c172c;
  text-align: right;
}

.orderDetailsCampaignSection {
  padding-top: 14px;
  padding-bottom: 8px;
}

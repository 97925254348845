.orderDetailsFooter {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -2px 16px 0px rgba(37, 59, 97, 0.2);
  height: 81px;
  width: 100%;
  margin-top: auto;
}

.orderDetailsFooterQuote {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.orderDetailsFooterQuoteTotal {
  font-size: 14px;
  font-weight: 600;
  color: #0c172c;
}

.orderDetailsFooterQuoteUnits {
  font-size: 12px;
  color: #707087;
}

.orderDetailsFooterButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.orderDetailsFooterButtonPrint {
  padding: 10px;
  background: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderDetailsFooterButtonPrint:hover {
  background: #f5f5f5;
}

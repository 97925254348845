.mob-d2d-hidden {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.desk-d2d-hidden {
  @media screen and (min-width: 769px) {
    display: none !important;
  }
}

.display-none {
  display: none;
}

/* Mob Version D2D */
@media screen and (max-width: 768px) {
  .mob-d2d-container {
    overflow: hidden;
    display: flex !important;
    flex-direction: column !important;
    position: relative !important;
  }

  .mob-d2d-container-wrapper {
    overflow-y: auto !important;
    padding-right: 0px !important;

    div:first-child {
      min-width: 0 !important;
    }
  }

  .mob-d2d-next-btn {
    width: calc(100vw - 32px) !important;

    position: absolute !important;
    left: 50% !important;
    bottom: 15px !important;
    transform: translateX(-50%) !important;
    z-index: 999 !important;
    background-color: transparent !important;

    span {
      width: 100% !important;

      p {
        width: 100% !important;
      }
    }

    div {
      width: 100% !important;
      background: transparent !important;
      border-radius: 12px !important;
      background-color: white !important;
    }
    p {
      width: 100% !important;
    }
  }

  .mob-padding-next-btn {
    p {
      padding-right: 0 !important;
    }
  }

  .mob-h2h-campaign-location-wrapper {
    padding: 0 !important;
    /* height: 40px !important; */

    div:first-child {
      width: 100% !important;
    }
  }

  .mob-d2d-h2h-campaign-location-wrapper {
    width: 100% !important;
  }

  .mob-h2h-compain-campaign-location-wrapper {
    overflow-y: visible !important;
    padding: 0 !important;
  }

  .mob-d2d-cities-autocomplete {
    min-width: 100% !important;
    margin-left: 0 !important;

    fieldset {
      border: none !important;
    }

    & > div {
      border-radius: 8px !important;
    }

    input {
      height: 20px !important;
    }
  }

  .mob-d2d-audience-form-wrapper {
    margin: 0 !important;
    border-radius: 8px !important;
  }

  .mob-d2d-audience-form {
    position: absolute !important;
    width: calc(100vw - 32px) !important;
    top: 8px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    border-radius: 8px !important;
    height: 40px !important;
    background: white !important;
  }

  .mob-d2d-mob-map-header {
    background: none !important;
    width: calc(100% - 32px) !important;
    top: 8px !important;
    right: 50% !important;
    transform: translateX(50%) !important;
    padding: 0 !important;

    &.existCity {
      top: 56px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      width: calc(100% - 32px) !important;
    }

    &.removeAutocompleteH2H {
      display: none !important;
    }
  }

  .mob-place-autocomplete {
    background: white !important;
    padding-right: 0 !important;
    border-radius: 12px !important;
    width: 100% !important;

    input {
      height: 25px !important;

      &::placeholder {
        font-size: 14px !important;
      }
    }
  }

  .mob-selected-city-input-box {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 0 12px !important;
    border-radius: 8px !important;
    border: 1px solid #e2e2fc !important;
    max-height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
      width: 100% !important;
    }

    &.deleteMenu {
      padding: 0 0 !important;
    }
  }

  .mob-d2d-audi-for-wrapper {
    width: 100% !important;
    padding-left: 0 !important;

    .accordion {
      border-radius: 4px !important;

      div:first-child {
        /* justify-content: start !important; */
        width: 100%;
      }
    }

    .accordion-wrapper {
      &.existCity {
        pointer-events: none;
      }
    }

    .accordion-trigger {
      cursor: pointer;
      transition: rotate 0.25s linear;
      div {
        width: 100% !important;
        margin-bottom: 0 !important;
        display: flex;
        justify-content: space-between;
      }

      svg {
        &.open {
          transform: rotate(180deg) !important;
        }
        &.existCity {
          fill: rgb(177, 170, 200, 1) !important;
        }
      }
    }

    .accordion-details {
      max-height: 0;
      transition: max-height 0.25s linear;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
    }
    .accordion-details.open {
      max-height: 165px !important;
      transition: max-height 0.25s linear;
      position: relative;
      top: -100%;
      overflow: auto;
      opacity: 1;
      overflow: auto;
      pointer-events: all;
    }
    .accordion-details.open.expanded {
      top: 0;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: rotate 0.25s linear;

    &.open {
      transform: rotate(180deg) !important;
    }
  }

  .bom-d2b-bottom-block {
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 76px !important;
    background-color: rgb(255, 255, 255) !important;
    padding: 0 12px !important;
    width: calc(100vw - 32px) !important;
    border-radius: 8px !important;
    padding: 12px !important;

    .mob-campaign-name {
      font-size: 16px !important;
      width: 100% !important;
      margin-left: -25px;
      margin-bottom: 10px !important;
    }

    .mob-total-cost {
      display: none !important;
    }

    .mob-sub-bottom-block {
      display: flex;
      justify-content: space-between;

      .mob-column {
        display: grid;
        /* flex-direction: column; */
        /* align-items: center; */
        /* justify-content: center; */
        width: 100%;
        padding: 0 0;
        text-align: center;

        span {
          /* margin: auto; */

          &:first-child {
            font-weight: bold;
          }
        }
      }

      .value {
        font-weight: bold;
      }
      .value,
      .label {
        display: flex;
        justify-content: center;
        font-size: 16px;
      }
    }
    .separate {
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 40px;
        background-color: #e2e2fc;
        margin: 0 10px;
        margin: 0 auto !important;
      }
    }
  }

  .mob-location-card-container {
    width: 100% !important;
    border: 1px solid #e2e2fc !important;
  }
}

/* Mob Version H2H */

.bom-h2h-bottom-block {
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 76px !important;
  background-color: rgb(255, 255, 255) !important;
  padding: 0 12px !important;
  width: calc(100vw - 32px) !important;
  border-radius: 8px !important;
  padding: 12px !important;

  .mob-campaign-name {
    font-size: 16px !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .mob-total-cost {
    display: none !important;
  }

  .mob-sub-bottom-block {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .mob-column {
      display: grid;
      /* flex-direction: column; */
      /* align-items: center; */
      /* justify-content: center; */
      width: 100%;
      padding: 0 0;
      text-align: center;

      span {
        /* margin: auto; */

        &:first-child {
          font-weight: bold;
        }
      }
    }

    .value {
      font-weight: bold;
    }
    .value,
    .label {
      display: flex;
      justify-content: center;
      font-size: 16px;
    }
  }
  .separate {
    &::before {
      content: "";
      display: block;
      width: 1px;
      height: 40px;
      background-color: #e2e2fc;
      margin: 0 10px;
      margin: 0 auto !important;
    }
  }
}

.mob-accordion-trigger-header {
  padding-left: 0 !important;
}

.mob-h2h-audi-for-wrapper {
  width: 100% !important;
  padding-left: 0 !important;

  .accordion {
    border-radius: 4px !important;

    div:first-child {
      /* justify-content: start !important; */
      width: 99%;
    }
  }

  .accordion-wrapper {
    &.existCity {
      pointer-events: none;
    }
  }

  .accordion-trigger {
    cursor: pointer;
    transition: rotate 0.25s linear;
    div {
      width: 100% !important;
      margin-bottom: 8px !important;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    svg {
      &.open {
        transform: rotate(180deg) !important;
      }
      &.existCity {
        fill: rgb(177, 170, 200, 1) !important;
      }
    }
  }

  .accordion-details {
    max-height: 0;
    transition: max-height 0.25s linear;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
  }
  .accordion-details.open {
    max-height: 232px !important;
    transition: max-height 0.25s linear;
    position: relative;
    top: -100%;
    overflow: auto;
    opacity: 1;
    overflow: auto;
    pointer-events: all;
  }
  .accordion-details.open.expanded {
    top: 0;
  }

  /* .mob-location-card-container {
    width: 100vw !important;
  } */
}

.orderDetailsModalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  min-width: 570px;
  overflow-y: auto;
}

@media (max-width: 570px) {
  .orderDetailsModalContainer {
    min-width: 100%;
  }
}

.orderDetailsModalBody {
  padding: 24px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.orderDetailsModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 32px 6px 32px;
}

.closeButton {
  cursor: pointer;
  padding: 8px;
}

.orderDetailsModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0 32px;
}

.orderDetailsModalTitle {
  font-size: 40px;
  font-weight: 800;
  padding-bottom: 9px;
}

.orderDetailsModalSubmitterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 50px;
}

.orderDetailsModalSubmitterValue {
  font-weight: 700;
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.orderDetailsModalCloseButton {
  cursor: pointer;
  padding: 0;
}

.orderDetailsLoadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.designPreviewSection {
  padding-top: 8px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e2fc;
}

.designPreviewTitle {
  font-size: 14px;
  font-weight: 700;
  color: #0c172c;
}

.alert-container {
  background: #ffe7eb;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 16px;
}

.alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.alert-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ee4360;
}

.alert-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #011533;
  margin: 0;
  margin-bottom: 16px;
}

.alert-edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border: 1px solid;
  border-radius: 12px;
  color: #ee4360;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
}

.alert-edit-button:hover {
  background: #ffe7eb;
}

.alert-edit-button:active {
  color: #d72b4f;
}

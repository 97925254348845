.container {
  position: relative;
  display: flex;
  background-color: #f2f2f6;
  width: fit-content;
  border-radius: 2px;
}

.isMultiple {
  width: 100%;
  justify-content: space-between;
  background-color: white;
}

.marginRight {
  margin-right: 8px;
}

.hasOptions {
  background-color: #e2e2fc;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 2px;
  padding: 4px 6px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  & svg {
    margin-right: 2px;
  }
}

.clear {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid #e2e2fc;
  background-color: white;
  cursor: pointer;
  & svg {
    margin-right: 5px;
  }
}

.label {
  font-family: "Proxima Nova Light";
  margin-right: 5px;
  color: #4f5b94;
  font-size: 12px;
}

.amountOfSelectedOptions {
  font-family: "Proxima Nova Light";
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b7c4ff;
  color: #2e3860;
  width: 14px;
  height: 14px;
  font-size: 10px;
}

.separator {
  width: 1px;
  height: 16px;
  background-color: #e4e4ea;
  margin: 0 5px;
}

.separatorWidthOptions {
  background-color: #c0c0c0;
}

.options {
  position: absolute;
  width: 272px;
  top: 30px;
  left: 0px;
  border: 1px solid #f2f2f6;
  border-radius: 4px;
  z-index: 999999;
  background-color: white;
}

.multipleOptions {
  top: 0px;
  left: 100%;
}

.optionsContainer {
  padding: 0 2px 0 10px;
  overflow-y: scroll;
  max-height: 150px;
}

.option {
  padding: 7px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}

.option:hover {
  background-color: #e2e2fc;
}

.option:last-child {
  margin-bottom: 20px;
}

.tickContainer {
  width: 20px;
  display: flex;
  align-items: center;
}

.optionLabel {
  font-family: "Proxima Nova Bold";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  margin-left: 6px;
  width: 100%;
  color: #011533;
}

.optionsHeader {
  padding: 12px 10px;
  margin-top: 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionsTitleContainer {
  display: flex;
  align-items: center;
  & svg {
    margin-right: 4px;
  }
}

.optionsTitle {
  font-family: "Proxima Nova Bold";
  font-weight: 700;
  font-size: 14px;
  color: #4f5b94;
}

.footer {
  height: 48px;
  display: flex;
  align-items: center;
  box-shadow: 0px -4px 8px 0px #2737541a;
  padding: 0 16px;
}

.cancel {
  border: 1px solid #e2e2fc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  margin-right: 10px;
  background-color: white;
  width: 69px;
  height: 32px;
  color: #2e3860;
  font-weight: 600;
}

.update {
  background: #ee4360;
  border: 1px solid #ee4360;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  width: 161px;
  height: 32px;
}

@media (height >= 768px) {
  .optionsContainer {
    max-height: 280px;
  }
}

.inputFieldError {
  margin-top: 8px;
  color: #f90606;
}

.required {
    font-family: Proxima Nova, sans-serif;;
    font-size: 14px;
    font-weight: 400;
    color: #D72B4F;
    padding-left: 2px;
}

.inputField {
    height: 44px;
}

.errorContainer {
    color: #f90606;
    font-family: Proxima Nova, sans-serif;;
    font-size: 14px;
    font-weight: 400;
    padding-top: 4px;
}

.inputLabelContainer{
  margin-bottom: 4px;
}

.inputFieldLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inputDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

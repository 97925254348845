.mainContainer {
  background: #f9f9ff;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow: scroll;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 28px;
}

.backButton {
  font-size: 16px;
  color: #4f5b94;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.backButtonIcon {
  width: 14px;
  height: 14px;
}

.titleSection {
  display: flex;
  padding: 24px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.loadingContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .contentContainer {
    width: 1080px;
    justify-self: center;
  }
}

.designSelectionCardContainer {
  margin-bottom: 20px;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(39, 55, 84, 0.08);
}

.designSelectionCardHeaderContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;
}

.designSectionSubtitle {
  align-self: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.seeMoreToggle {
  display: flex;
  padding: 0px 8px;
  gap: 8px;
  align-items: center;
}

.seeMoreToggle:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.dragAndDropAndFormatWrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}

.toggleIcon {
  color: #2e3860;
}

.toggleIconOpen {
  transform: rotate(180deg);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.designSelectionContent {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, margin 0.5s ease-in-out;
  opacity: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  gap: 20px;
  max-height: 100vh;
}

.designSelectionCardContainer.closed > .designSelectionContent {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, margin 0.5s ease-in-out;
  max-height: 0;
  opacity: 0;
  margin: 0;
}

.designFileWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 78%;
}

.dragAndDropWrapper {
  align-self: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 208px;
  padding: 10px;
  z-index: 1;
  border-radius: 8px;
  border: 1px solid #e2e2fc;
}

.previewWrapper {
  align-self: flex-start;
  width: 100%;
  display: flex;
  height: 208px;
  z-index: 1;
  border-radius: 8px;
}

.selectedFormatWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 22%;
}

.selectedFormatTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #aeafc7;
}

.editButton {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  text-decoration: underline;
  color: #4f5b94;
  cursor: pointer;
}

.customListBullet {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2e3860;
}

.uploadInstructions {
  align-self: flex-start;
}

.downloadTemplateButton {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
  max-width: max-content;
  cursor: pointer;
  padding: 0;
  align-self: flex-start;
}

.downloadTemplateButton svg {
  margin-right: 5px;
}

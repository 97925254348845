@media screen and (max-width: 768px) {
  .mob-autocomplete {
    position: absolute !important;
    top: 20px !important;
  }

  .MuiSnackbar-anchorOriginBottomRight {
    margin-left: auto;
    width: fit-content;
  }

  .mob-next-button {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 12px !important;
    order: 4 !important;

    p {
      width: calc(100vw - 34px) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 0 !important;

      img {
        position: static !important;
        margin-left: 8px;
      }
    }
  }

  .mob-vertical-arrow-wrapper {
    margin-right: 10px !important;

    p {
      margin-left: -20px !important;
      bottom: 50% !important;
      transform: translateY(50%) rotate(-90deg) !important;
    }
  }

  .mob-horizontal-arrow-wrapper {
    margin-top: 0 !important;

    p {
      margin-top: 5px !important;
    }
  }

  .mob-name-wrapper {
    display: flex !important;
    align-items: center !important;
    p {
      font-size: 12px !important;
      text-align: center !important;
    }
  }

  /* zoom map */
  .gmnoprint {
    display: none !important;
  }

  .mob-main-map-container {
    display: flex !important;
    gap: 4px !important;
    flex-direction: column !important;
    width: calc(100vw - 35px) !important;
    position: absolute !important;
    left: 50% !important;
    bottom: 18px !important;
    transform: translateX(-50%) !important;
  }

  .mob-map-container {
    min-width: 0px !important;
    height: calc(100vh - 80px) !important;
  }

  /* AddressBlock */
  .mob-hidden {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  .desktop-hidden {
    padding: 0 !important;
    @media screen and (min-width: 769px) {
      display: none !important;
    }
  }

  .mob-address-container {
    padding: 5px 10px !important;
    width: calc(100vw - 35px) !important;
    position: static !important;
    transition: top 0.25s linear;
    overflow: hidden !important;
    height: auto !important;
    order: 1 !important;
    background: white !important;

    .accordion {
      border-radius: 4px !important;

      div:first-child {
        justify-content: flex-start !important;
      }
    }

    .accordion-trigger {
      /* height: 35px !important; */
      cursor: pointer;
      transition: rotate 0.25s linear;
      div {
        margin-bottom: 0 !important;
        display: flex;
        justify-content: space-between;
      }

      svg.open {
        transform: rotate(180deg) !important;
      }
    }

    .accordion-details {
      max-height: 0;
      /* overflow: hidden; */
      transition: max-height 0.25s linear;
    }
    .accordion-details.open {
      max-height: 165px !important;
      transition: max-height 0.25s linear;
      position: relative;
      top: -100%;
      overflow: auto;
    }
    .accordion-details.open.expanded {
      top: 0;
    }
  }

  /*  SummaryBlock, RouteStatistics*/
  .mob-summary-container,
  .mob-statistics-block-container {
    position: static !important;
    width: calc(100vw - 35px) !important;
  }

  .mob-summary-container {
    order: 3 !important;
  }

  .mob-statistics-block-container {
    padding: 0 !important;
    display: block !important;
    order: 2 !important;
    border-radius: 8px !important;
  }

  .mob-search-bar-wrapper {
    position: absolute !important;
    top: 95px !important;
    transform: translateX(-50%) !important;
    left: 37% !important;
    gap: 4px !important;
    width: calc(70vw - 34px) !important;
    z-index: 999 !important;

    div:first-child {
      background: white !important;
      border-radius: 8px;
    }
  }

  .mob-radius-select {
    min-width: 55px !important;
    position: absolute !important;
    top: -17px !important;
    transform: translateX(100%) !important;
    right: 50% !important;
    width: calc(23vw - 34px) !important;
    z-index: 999 !important;
  }

  .mob-segments-header-wrapper {
    height: 5px !important;
  }

  .mob-radius-wrapper {
    margin-left: 0px !important;
  }

  .mob-icon {
    left: 69px !important;
    z-index: 1000 !important;
    margin-right: -35px;
  }

  .mob-icon-h2h {
    right: 113px !important;
  }

  .mob-route-title {
    font-weight: bold !important;
    font-size: 18px !important;
  }
}

.mob-routestatistics-padding {
  padding: 5px 10px !important;
}

.loaderContainer {
  width: 100%;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f6;
}

.pdfPreviewContainer {
  width: 100%;
  height: 208px;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f6;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
}

.pdfPreview {
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.previewWrapper {
  position: relative;
}

.cornerMark {
  width: 6px;
  height: 6px;
  z-index: 10;
}

.leftTop {
  position: absolute;
  top: -6px;
  left: -6px;
  border-bottom: 1px solid #4f5b94;
  border-right: 1px solid #4f5b94;
}

.leftBottom {
  position: absolute;
  bottom: -6px;
  left: -6px;
  border-top: 1px solid #4f5b94;
  border-right: 1px solid #4f5b94;
}

.rightTop {
  position: absolute;
  top: -6px;
  right: -6px;
  border-bottom: 1px solid #4f5b94;
  border-left: 1px solid #4f5b94;
}

.rightBottom {
  position: absolute;
  bottom: -6px;
  right: -6px;
  border-top: 1px solid #4f5b94;
  border-left: 1px solid #4f5b94;
}

.sideTitle {
  align-self: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 4px;
  color: #aeafc7;
}

.nameAndDelete {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileName {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #2e3860;
}

.deleteIcon {
  cursor: pointer;
  margin-right: 20px;
}

.previewImage {
  display: block;
  z-index: 1;
}

@media print {
  canvas {
    display: none !important;
  }

  .print-only-image {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .previewImage {
    position: relative !important;
    page-break-inside: avoid;
  }
}

/* Global styles - edit only if you have to */
* {
  font-family: Proxima Nova, sans-serif;
}

p,
div,
span {
  font-family: Proxima Nova, sans-serif !important;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./static/fonts/ProximaNova/ProximaNova-Regular.ttf");
  font-style: normal;
}

/* .mob-display-wh {
  width: 288.23px !important;
  height: 202.93px !important;
 

  @media screen and (min-width: 768px) {
    width: 508.23px !important;
    height: 352.93px !important;
   
  }
} */
/* 
.mob-stock-horizontal-arrow {
  width: 279px !important;

  @media screen and (min-width: 768px) {
    width: 496px !important;
  }
} */
/* 
.mob-stock-vertical-arrow {
  height: 190px !important;

  @media screen and (min-width: 768px) {
    height: 340px !important;
  }
} */

.desk-hidden {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .MuiSnackbar-anchorOriginBottomRight {
    margin-left: auto;
    width: fit-content;
  }
  body {
    height: 100vh !important;
  }

  .display-none {
    display: none !important;
  }

  .flex-direction {
    flex-direction: column !important;
  }

  .mob-header-wrapper {
    height: auto !important;
  }

  .mob-grid-wrapper {
    width: 100% !important;
  }

  .mob-navigation-back-and-home {
    left: 20px !important;
    display: flex !important;
    gap: 15px !important;
    align-items: center !important;
  }

  .margin-null {
    margin: 0 !important;
  }

  .mob-right-side {
    padding: 30px 20px !important;
  }

  .mob-header {
    /* align-items: flex-start !important; */
    background-color: #e8e8fd !important;
    padding: 9px 12px !important;
    border-radius: 8px !important;
  }

  .mob-campaign-name {
    font-size: 22px !important;
  }

  .mob-tabs-wrapper {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    display: grid !important;
    grid-template-columns: auto !important;
    justify-content: center !important;
  }
  .mob-preview-wrapper {
    align-items: flex-start !important;
    padding: 0 15px !important;
    display: grid !important;
    justify-content: center !important;
  }

  .mob-preview-text {
    padding: 0 !important;
    margin: 0 auto !important;
  }

  .mob-content-wrapper {
    grid-template-columns: 1fr !important;
    min-width: 0 !important;
  }

  .mob-container {
    width: auto !important;
    margin: 0 !important;
  }

  .mob-left-side {
    padding: 30px 20px !important;
  }

  .mob-image-wrapper {
    width: 100% !important;
    background: red !important;
  }

  .mob-next-btn {
    margin-top: 20px !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .mob-next-btn-not-preview {
    margin-top: 40px !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .mob-navigation-wrapper {
    display: block !important;
  }

  .mob-item {
    padding: 5px !important;
  }

  .mob-tab-wrapper {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .mob-separator {
    display: none !important;
  }

  .dots-container {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: -30px !important; */
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #d3d3d3;
    margin: 0 4px;
  }

  .active-dot {
    background-color: #000;
    width: 10px;
    height: 10px;
  }

  .mob-pages-wrapper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;

    .second-child {
      margin-top: 16px !important;
    }
  }

  .mob-page {
    width: 100% !important;
    min-height: auto !important;
  }

  .mob-row {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .mob-text {
    align-items: flex-start !important;
  }

  .mob-flex_1 {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .mob-table-parent-4-child {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;

    div:first-child {
      grid-column: 1;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(4) {
      grid-column: 4;
      grid-row: 1;
      margin-right: auto !important;
    }
  }

  .mob-table-parent-3-child {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;

    div:first-child {
      grid-column: 2;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(2) {
      grid-column: 3;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(3) {
      grid-column: 4;
      grid-row: 1;
      margin-right: auto !important;
    }
  }

  .mob-table-parent-2-child {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;

    div:first-child {
      grid-column: 1;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(2) {
      grid-column: 4;
      grid-row: 1;
      margin-right: auto !important;
    }
  }

  .mob-table-parent-error-child {
    margin-top: 10px !important;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;

    div:first-child {
      grid-column: 1;
      grid-row: 1;
      margin-right: auto !important;
    }
    div:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      margin-left: 10px !important;
    }
  }

  .mob-sales-taxes-ml-10 {
    margin-left: 10px !important;
  }

  .margin-left {
    margin-left: 0 !important;
  }

  .margin-bottom {
    margin-bottom: 10px !important;
  }

  .justify-content {
    justify-content: flex-start !important;
  }

  .grand-total {
    justify-content: space-between !important;
  }

  .mob-paper-height {
    height: calc(100% - 80px) !important;
  }
  .mob-button-wrapper {
    width: 100% !important;
  }
}

.mob-arrow-nav {
  padding: 0 !important;
  display: flex !important;
  width: 20px !important;

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .mob-attach-btn {
    border: none !important;
    width: 100% !important;
    margin: auto !important;
  }
  .mob-text-title {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: bold !important;
    color: #fff !important;
    margin: 0 !important;
    pointer-events: none !important;
    width: 100% !important;
    height: 50px !important;
    text-align: center !important;
    border-radius: 12px !important;
  }

  .bg-transparent {
    background: transparent !important;
    padding: 0 !important;
    line-height: 1.5;
    font-size: 16px !important;
  }

  .mob-date-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    overflow-y: auto;
    max-width: calc(100vw - 32px) !important;

    div {
      width: 100% !important;
    }
    .c-iwbvUF {
      padding: 16px !important;
    }
  }

  .mob-wrapper {
    padding: 16px 16px 32px !important;
    overflow-y: auto !important;
    height: fit-content !important;
  }
  .MuiDialog-paperFullWidth {
    text-align: center !important;
    width: calc(100% - 32px) !important;
    height: auto;
  }
  .MuiDialog-paper {
    margin: 0px !important;
    position: relative;
    overflow-y: auto;
  }
  .mob-btns-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 30px !important;
    width: 100% !important;

    button {
      margin-right: 0 !important;
      width: 100% !important;
    }
  }

  /* enter coupon */
  .mob-coupon-triger {
    margin: auto !important;
    padding: 0;
  }
  .mob-coupon-code-wrapper {
    width: 100% !important;
    div:first-child {
      width: 100% !important;
      display: flex;
      gap: 30px !important;
      justify-content: space-between !important;
    }
    form {
      width: 100% !important;
    }
  }
  .mob-coupon-input {
    width: 100% !important;
  }
  .mob-apply-btn {
    margin: 0 !important;
  }

  .mob-btns-group-delete-pdf,
  .mob-btns-group-submit-pay {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 16px !important;
    width: 100% !important;
    flex-direction: column !important;

    div:first-child,
    div:nth-child(2),
    button {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mob-dialog-content {
    padding: 0px 16px 32px !important;
    overflow-y: hidden !important;
  }
  .mob-dialog-content__title {
    text-align: center !important;
    font-size: 22px !important;
  }

  .MuiSnackbar-anchorOriginBottomRight {
    margin-left: auto;
    width: fit-content;
  }

  /* flyer page */
  .mob-flyer-wrapper {
    padding: 0 16px !important;
    overflow: unset !important;
    height: fit-content !important;

    .mob-header-title {
      text-align: center !important;
      text-wrap: wrap !important;
      width: 100% !important;
    }
    .mob-flyer-select-design-wrapper {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column !important;
      gap: 16px !important;
      width: auto !important;

      div:first-child,
      div:nth-child(2) {
        margin: 0 !important;
      }
    }
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mob-settings-row {
    width: 350px !important;
  }
  .mob-navigation-btn {
    padding-right: 52px !important;
  }
}

.modalBackground {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  width: 555px;
  border-radius: 8px;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.closeButton {
  cursor: pointer;
  padding: 0;
  color: #292d32;
}

.modalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}

.descriptionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #0c172c;
  font-size: 16px;
}

.uploadedCSVFile {
  width: 100%;
  align-self: flex-start;
  padding: 12px 16px;
  color: #2e3860;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e2e2fc;
  border-radius: 8px;
  margin-bottom: 30px;
}

.fileNameContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 16px;
}

.fileStick {
  margin-right: 5px;
}

.fileName {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
}

.submittingProcess {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.submittingProcessLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.progressBarContainer {
  flex: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.modalFooter {
  display: flex;
  width: 100%;
  justify-content: center;
}

.cancelButton {
  width: 98px !important;
  border: 1px solid black !important;
}

.couponInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.couponInputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.couponInputLabel {
  color: #0c172c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.couponInputFieldContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.couponInputFieldContainer > div {
  flex: 1;
}

.applyButton {
  min-width: 112px;
  height: 42px;
  color: #011533;
  background: transparent;
  border: 1px solid #e2e2fc;
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}

.applyButton:hover {
  background: #f5f5f5;
}
.applyButton:disabled {
  background: #f2f2f6;
  cursor: default;
}

.couponError {
  color: #e00404;
  font-size: 12px;
  line-height: 16px;
}

.appliedCouponChip {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  background: #f2f2f6;
  border-radius: 4px;
  width: fit-content;
}

.chipLabel {
  color: #2e3860;
  font-size: 14px;
  font-weight: 500;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #707087;
}

.removeButton:hover {
  color: #2e3860;
}
